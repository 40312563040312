.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%280, 0, 0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.logo {
  width: 75%;
  max-width: 100%;
}

.dropdown-item.nav-link {
  margin: 0;
}

a.nav-link {
  margin: 0 22px;
}

.nav-container {
  width: 90vw;
  margin: 0 auto;
}

.accentFont {
  color: var(--logo) !important;
  font-family: "Courgette";
}

@media screen and (max-width: 1200px) {
  .logo {
    width: 50vw;
    display: block;
    margin: 0 auto;
  }

  .nav-link {
    margin: 0;
  }
}

@media screen and (max-width: 639px) {
  .logo {
    width: 100%;
  }
}
