.list-group-item.active {
  background-color: #15748b; /* Change the background color for selected item */
  color: #fff; /* Change the font color for selected item */
}

.list-group-item {
  font-family: "Josefin Sans", sans-serif; /* Change the font family for list items */
  font-size: 16px; /* Change the font size for list items */
}

h4#serviceName {
  margin-top: 2%;
}

.video-player {
  position: relative;
  padding-bottom: 60.333333333%;
}

.video-player > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
