.footer {
  bottom: 0;
  font-family: "Josefin Sans", "Helvetica Neue", sans-serif;
  border-bottom: var(--logo) solid 10px;
}

.footer h3 {
  font-family: "Courgette";
  color: var(--logo);
}

.footer-nav-item {
  text-decoration: none;
  color: var(--body);
}

.footer-nav-item:hover {
  color: var(--logo);
}

.map {
  display: block;
  border: none;
  top: 0;
  width: 100%;
  height: 20vh;
}

@media only screen and (max-width: 992px) {
  .map {
    width: 100%;
  }
}
