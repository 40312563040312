.carousel {
  box-sizing: border-box;
  background: #eee;
}

/* .carousel img {
  display: block;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .carousel img {
    height: 400px;
  }
} */
