@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Courgette&family=Josefin+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  --logo: #15748b;
  --body: #0a0903;
  --accent: #acadae;
  --link: #2f2f30;
}

body,
html {
  font-family: "Josefin Sans", "Helvetica Neue", sans-serif;
  border-top: var(--logo) solid 5px;
  height: 100%;
  background-color: var(--logo);
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #15748b !important;
}

.full {
  background-color: #fff;
}

.navbar a {
  color: var(--body);
  font-family: "Josefin Sans", "Helvetica Neue", sans-serif;
}

.navbar .btn {
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Josefin Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: var(--link);
  font-family: "Josefin Sans", "Helvetica Neue", sans-serif;
}

p {
  font-family: "Josefin Sans", "Helvetica Neue", sans-serif;
}

.content {
  min-height: calc(100vh - 492px);
  margin-bottom: 1%;
}

@media only screen and (max-width: 992px) {
  .navbar-brand {
    font-size: 1.25rem !important;
  }
}
