.contact-map {
    width: 100%;
    height: 98%;
    border: none;
}

@media only screen and (max-width: 992px) {
    .contact-map {
        width: 100%;
        height: 50vh;
    }
}