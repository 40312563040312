.body {
  width: 100%;
  margin: 0 auto;
  font-family: "Josefin Sans", "Helvetica Neue", sans-serif;
}

h2,
h3 {
  font-family: "Courgette";
}

p {
  font-family: "Cormorant Garamond";
}

article:not(#hero):not(#promo) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.body-container {
  width: 85vw;
  /* margin: auto; */
}

#hero {
  width: 75vw;
  margin: 0 auto 1rem auto;
}

#promo {
  background-color: var(--logo);
  text-align: center;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

#promo h1,
#promo h2 {
  color: #ffffff;
}

#promo a {
  color: #ffffff;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: #ffffff !important;
  color: var(--logo) !important;
}

article:not(#promo) h1 {
  color: var(--body);
}

article:not(#promo) h2 {
  color: var(--logo);
}

#welcome {
  background-color: #eae0cc;
}

#welcome .welcome-message {
  /* width: 70%; */
  margin: auto;
  padding: 1rem 0;
}

#testimonials {
  background-color: #a0a083;
}

article > div {
  padding: 2% 0;
}

#testimonials .carousel {
  background: none;
}

#testimonials .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

#testimonials h3 {
  color: var(--logo);
}

#insurance {
  background-color: #d9d9d9;
}

#insurance a {
  color: inherit;
}

@media only screen and (max-width: 992px) {
  .body {
    background-color: #fff;
  }

  #hero {
    width: 85vw;
    margin: 0 auto 1rem auto;
  }

  .carousel h3 {
    font-size: large;
  }
}
