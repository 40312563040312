.case-study-image {
  display: block;
  width: 100%;
  max-width: 550px;
}

.accordion {
  margin-bottom: 10%;
}

.layout {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(auto-fit, 240px);
    grid-template-columns: repeat(auto-fit, 372px);
    gap: 8px;
  }